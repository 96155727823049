import React, { Component } from "react";
import Helmet from "react-helmet";
import Header from "../../components/header";
import EazyHeader from "../../components/header-eazy";
import LayoutWrapper from "../../components/layout";
import WithProviders from "../../components/WithProviders";
import "./pricing.css";
import { Choice, Extensions } from "../index";

import website from "../images/website.svg";

import catalog from "../images/catalogue.svg";

import admin from "../images/admin.svg";

import analytics from "../images/analytics.svg";

import marketing from "../images/marketing.svg";

import mobileReady from "../images/mobile-ready.svg";

import shipping from "../images/shipping-rules.svg";

import order from "../images/order-management.svg";

import security from "../images/security.svg";

import hosting from "../images/hosting.svg";

import pos from "../images/online-pos.svg";

import payment from "../images/online-payment.svg";

import multistore from "../images/multistore.svg";

import multiuser from "../images/multi-users.svg";

import marketingCamp from "../images/marketing-campaigns.svg";

import orderProcess from "../images/order-process.svg";

import deliveryArea from "../images/delivery-area-support.svg";

import multishipping from "../images/multi-shipping.svg";

import android from "../images/oreo.svg";

import apple from "../images/apple.svg";

const FrequentlyAskedQuestions = [
  {
    question: "Can I try SmartStore Eazy without paying?",
    answer: (
      <p>Yes. Just sign up and avail 15 days free trial of the platform.</p>
    ),
  },
  {
    question:
      "Do I need to enter my credit card number or any other payment details to take the trial?",
    answer: (
      <p>
        No. There is no requirement to enter any payment details to take the
        trial. You can just sign-up and start using the platform
      </p>
    ),
  },
  {
    question: "What happens once my trial is over?",
    answer: (
      <p>
        If you want to continue using the platform, then you will have to pay by
        recharging your in-built wallet with a minimum of Rs.2,000. In case you
        don’t recharge your wallet within 15 days of trial expiry, your account
        will get permanently deleted.
      </p>
    ),
  },
  {
    question: "How do I pay?",
    answer: (
      <p>
        You can pay by charging your{" "}
        <a
          href="https://dashboard.zopsmart.com/user/account-balance"
          target="_blank"
          rel="noopener noreferrer"
        >
          account-wallet
        </a>{" "}
        from your admin-panel. Our payment gateway accepts all standard credit
        cards, debit cards, bank transfer and e-wallets.
      </p>
    ),
  },
  {
    question: "What features do I get during trial?",
    answer: (
      <p>
        You get all the features that a paid user gets. To see the complete list
        please visit SmartStore Eazy{" "}
        <a
          href="https://www.zopsmart.com/eazy/features"
          target="_blank"
          rel="noopener noreferrer"
        >
          features page
        </a>
      </p>
    ),
  },
  {
    question: "What are add-on features?",
    answer: (
      <p>
        Add-on features are functionalities that are specific to individual
        businesses and may not be needed by everyone. You can view all these
        functionalities and turn-on as per your need.
      </p>
    ),
  },
  {
    question: "Are add-on features chargeable?",
    answer: (
      <p>
        Most of the add-on features are free of charge and you just need to
        enable them to start using them. For paid add-on features, the payment
        is deducted from your account wallet as per the pricing of the
        individual feature.
      </p>
    ),
  },
  {
    question: "Can I turn-off add-on features anytime?",
    answer: (
      <p>
        Yes. But you need to be extremely careful while turning-off these
        features as you may lose their corresponding data permanently.
      </p>
    ),
  },
  {
    question: "Will you remind me if my account wallet balance is low?",
    answer: (
      <p>
        Yes. We will send you multiple reminders via sms and email once your
        account balance is below Rs.2000.
      </p>
    ),
  },
  {
    question: "What happens if I have negative balance in my wallet?",
    answer: (
      <div>
        <p>
          Case 1: If the wallet balance is negative for more than 15 days
          continuously then your account will be automatically disabled i.e.
          your website will be down, although you will be able to access your
          account-admin. As soon as you recharge your wallet and make the
          balance positive, your website will be live again.
        </p>
        <p>
          Case 2: If the wallet balance is negative for more than 60 days
          continuously then the account will be automatically deleted i.e. both
          your website and admin account will be deleted. All your data will be
          permanently lost.
        </p>
      </div>
    ),
  },
  {
    question:
      "If I want to stop using your platform, will I get my wallet-money back?",
    answer: (
      <p>
        Yes. At any time you can communicate to us to that you want to stop
        using our platform. We will refund your wallet amount after deduction of
        the running month’s rental payment and dues till date.
      </p>
    ),
  },
];

class FAQ extends Component {
  checkLinkClick = (e) => {
    if (e.target.tagName !== "A") {
      this.props.onToggle();
    }
  };
  render() {
    const { expanded, question, answer } = this.props;
    let containerStyle =
      !expanded || this.contentRef
        ? {
            maxHeight: expanded ? this.contentRef.offsetHeight + "px" : 0,
          }
        : {};
    return (
      <div
        className={`faq ${expanded ? "expanded" : ""}`.trim()}
        onClick={this.checkLinkClick}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        <div className="question">
          <h4>{question}</h4>
          <div className={`${expanded ? "up-arrow" : "down-arrow"}`.trim()}>
            &nbsp;
          </div>
        </div>
        <div className="content-container" style={containerStyle}>
          <div
            className={`answer muted`}
            ref={(node) => {
              this.contentRef = node;
            }}
          >
            <div>{answer}</div>
          </div>
        </div>
      </div>
    );
  }
}

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedIndex: null,
    };
    this.getFaqs = this.getFaqs.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle(index) {
    this.setState((prevState) => {
      let expandedIndex = prevState.expandedIndex === index ? null : index;
      return {
        expandedIndex,
      };
    });
  }

  getFaqs() {
    let _this = this;
    let expandedIndex = this.state.expandedIndex;
    let faqs = FrequentlyAskedQuestions.map((faq, index) => {
      return (
        <FAQ
          key={`faq-${index}`}
          onToggle={() => _this.toggle(index)}
          expanded={expandedIndex === index}
          question={faq.question}
          answer={faq.answer}
        />
      );
    });
    return faqs;
  }

  render() {
    const { language, updateLanguage, direction } = this.props;
    return (
      <LayoutWrapper
        showFooterForm
        language={language}
        location={this.props.location}
      >
        <div className="eazy-page">
          <Helmet title="SmartStore Eazy - Pricing" />
          <Header
            eazy
            language={language}
            updateLanguage={updateLanguage}
            direction={direction}
          />
          <EazyHeader
            links={[
              {
                href: "https://dashboard.zopsmart.com/login",
                className: "smartstore-login",
                label: "login",
              },
              {
                href: "https://dashboard.zopsmart.com/signup",
                className: "smartstore-signup",
                label: "sign up",
              },
            ]}
            current="pricing"
            hiddenHeader={this.state.hiddenHeader}
          />
          <div className="eazy-pricing">
            <section className="overview tc">
              <section className="brief mask">
                <h1>Simple pricing</h1>
                <p>Pay only for what you use</p>
              </section>
              <section className="price-box tc">
                <h4>
                  Everything to run your world-class online store for just
                </h4>
                <h1>
                  <span> &#8377;</span> 2000/-
                </h1>
                <small className="muted">per month</small>
                <a
                  href="https://dashboard.zopsmart.com/signup"
                  className="signup"
                >
                  <div className="action">START YOUR FREE TRIAL</div>
                </a>
                <ul>
                  <li>
                    <span />
                    15 Days free trial
                  </li>
                  <li className="divider" />
                  <li>
                    <span />
                    No credit card required
                  </li>
                </ul>
                {/* <p className='muted'>Includes all the features listed below and much more</p>
              <div className='down-arrow' /> */}
              </section>
            </section>
            <section className="details tc">
              <div className="background-border">
                <Choice
                  name="website"
                  heading="Website"
                  description="International quality website. Multiple themes. Blazing fast"
                  image={{ normal: website }}
                />
                <Choice
                  name="mobile"
                  heading="Mobile ready"
                  description="Responsive website that renders perfectly on tablets and mobiles"
                  image={{
                    normal: mobileReady,
                  }}
                />
                <Choice
                  name="admin"
                  heading="Admin console"
                  description="Easy to manage and change everything on your own"
                  image={{
                    normal: admin,
                  }}
                />
                <Choice
                  name="shipping"
                  heading="Shipping rules"
                  description="Rules set-up for delivery-area and shipping charges"
                  image={{
                    normal: shipping,
                  }}
                />
                <Choice
                  name="catalogue"
                  heading="Catalogue"
                  description="Unlimited products, variants and categories. Bulk upload available"
                  image={{
                    normal: catalog,
                  }}
                />
                <Choice
                  name="marketing"
                  heading="Marketing"
                  description="Unlimited coupons and offers. SEO ready. Free blogs"
                  image={{
                    normal: marketing,
                  }}
                />
                <Choice
                  name="operations"
                  heading="Order management "
                  description="Simple interface to manage your orders and customers"
                  image={{
                    normal: order,
                  }}
                />
                <Choice
                  name="analytics"
                  heading="Analytics & Reports"
                  description="Detailed reports and charts. Export option available."
                  image={{
                    normal: analytics,
                  }}
                />
                <Choice
                  name="security"
                  heading="Security"
                  description="SSL certificate for your website"
                  image={{
                    normal: security,
                  }}
                />
                <Choice
                  name="hosting"
                  heading="Hosting"
                  description="Hosting included. Also get free smartstore.express domain."
                  image={{
                    normal: hosting,
                  }}
                />
                <Choice
                  name="pos"
                  heading="Online POS"
                  description="Inbuilt POS to bill your walk-in customers"
                  image={{
                    normal: pos,
                  }}
                />
                <Choice
                  name="payment"
                  heading="Online payments"
                  description="Option to use our payment gateway or Integrate your own"
                  image={{
                    normal: payment,
                  }}
                />
              </div>
            </section>
            <section className="advanced-features">
              <div className="header tc">
                <h2>Advanced features</h2>
                <p className="muted">
                  Turn on/off these features as per your business requirement.
                  Pay only for what you use.
                </p>
                <hr />
              </div>
              <section className="advanced">
                <Extensions
                  name="multistore"
                  heading="Multiple stores"
                  description="Runs a chain of stores on single domain. Enables store-wise delivery footprint, catalogue, pricing, etc."
                  image={{
                    normal: multistore,
                  }}
                  pricing={{
                    heading: <span>&#8377;&nbsp;1,500</span>,
                    description: "per month for 2nd store onwards",
                  }}
                />
                <Extensions
                  name="multishipping"
                  heading="Multiple shipping rules"
                  description="Multiple rules for delivery footprint by postal-code, distance or area with corresponding shipping charges."
                  image={{
                    normal: multishipping,
                  }}
                  pricing={{
                    heading: <span>Free</span>,
                    description: (
                      <span>
                        for 1st rule.<span>&nbsp;&#8377;&nbsp;</span>100/month
                        for every additional rule
                      </span>
                    ),
                  }}
                />
                <Extensions
                  name="multiuser"
                  heading="Multiple user access"
                  description="Multiple user access to your store. Mapping of users to role. Role based access to admin."
                  image={{
                    normal: multiuser,
                  }}
                  pricing={{
                    heading: <span>Free</span>,
                    description: (
                      <span>
                        for 1st user.<span>&nbsp;&#8377;&nbsp;</span>100/month
                        for every additional user
                      </span>
                    ),
                  }}
                />
                <Extensions
                  name="marketing"
                  heading="Marketing campaigns"
                  description="Engine to send targeted marketing campaigns to your customers via SMS, e-mail and app-notification."
                  image={{
                    normal: marketingCamp,
                  }}
                  pricing={{
                    heading: <span>&#8377;&nbsp;0.001</span>,
                    description: (
                      <span>
                        per App-notifications, &#8377;&nbsp;0.01 per E-mail,
                        &#8377;&nbsp;0.12 per SMS
                      </span>
                    ),
                  }}
                />
                <Extensions
                  name="order"
                  heading="Order processing management"
                  description="Comprehensive module for picking, billing, packing of orders and monitoring & managing of staff."
                  image={{
                    normal: orderProcess,
                  }}
                  pricing={{
                    heading: "1%",
                    description: <span>of transaction value</span>,
                  }}
                />
                <Extensions
                  name="delivery"
                  heading="Delivery operations management"
                  description="Comprehensive module for route-planning, dispatching, and managing delivery operations."
                  image={{
                    normal: deliveryArea,
                  }}
                  pricing={{
                    heading: "1%",
                    description: "of transaction value",
                  }}
                />
              </section>
            </section>
            <section className="mobile-sec">
              <div className="header tc">
                <h2>Mobile Apps</h2>
                <p className="muted">
                  Just pay a nominal development fees and rock-bottom monthly
                  charges
                </p>
                <hr />
              </div>
              <section className="mobile-features">
                <Extensions
                  name="android"
                  heading="Android mobile-app"
                  description="Get your own world-class Android App. All changes you make to your website will instantly reflect in the App too."
                  image={{
                    normal: android,
                  }}
                  pricing={{
                    heading: <span>&#8377;&nbsp;50,000</span>,
                    description: (
                      <span>one time +&nbsp;&#8377;&nbsp;500/month/store</span>
                    ),
                  }}
                />
                <Extensions
                  name="ios"
                  heading="iOS mobile-app"
                  description="Get your own world-class iOS App. All changes you make to your website will instantly reflect in the App too."
                  image={{
                    normal: apple,
                  }}
                  pricing={{
                    heading: <span>&#8377;&nbsp;75,000</span>,
                    description: (
                      <span>one time +&nbsp;&#8377;&nbsp;500/month/store</span>
                    ),
                  }}
                />
              </section>
            </section>
            <section className="faq-section">
              <div className="header tc">
                <h2>Pricing - Frequently asked questions</h2>
                <p className="muted">
                  Can’t find an answer? Call us at (+91)&nbsp;80411&nbsp;26521
                  or email to info@zopsmart.com
                </p>
                <hr />
              </div>
              <section className="faqs">{this.getFaqs()}</section>
            </section>
          </div>
        </div>
      </LayoutWrapper>
    );
  }
}
export default WithProviders(Pricing);
